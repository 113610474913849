<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card-text>
        <div class="d-flex justify-center">
          <v-avatar
            size="120"
            class="mx-auto"
            style="
              background-color: #191919;
            "
          >
            <v-icon
              size="90"
              color="lightGray"
            >
              {{ icons.mdiTrashCanOutline }}
            </v-icon>
          </v-avatar>
        </div>
        <p class="font-weight-medium text--primary ma-0 mt-3 text-center">
          {{ loginData.fullname }}
        </p>
        <p class="text-xs text--disabled ma-0 mt-1 text-center">
          {{ loginData.info }}
        </p>
      </v-card-text>

      <v-card-text>
        <p class="text-center text-xl font-weight-semibold white--text mb-2">
          {{ t('video_cancelled.game_cancelled') }}
        </p>
        <p class="ma-0 pt-4 text-center">
          {{ t('video_cancelled.game_info') }}
        </p>
      </v-card-text>

      <v-card-text>
        <v-btn
          block
          color="secondary"
          type="submit"
          class="text-capitalize"
          @click="openTab('https://www.fanaty.com')"
        >
          {{ t('video_cancelled.download_app') }}
        </v-btn>
      </v-card-text>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { mdiTrashCanOutline } from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { openTab } from '@core/utils'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'

export default {
  setup() {
    const { t } = useUtils()
    const { decrypt } = useCryptoJs()

    const loginData = ref({})

    onMounted(async () => {
      const encryptLogData = await localStorage.getItem('logData')
      if (encryptLogData) {
        const decryptLogData = decrypt(JSON.parse(encryptLogData))
        loginData.value = {
          ...loginData.value,
          ...JSON.parse(decryptLogData),
        }
      }
    })

    return {
      loginData,

      openTab,

      // themeConfig
      appLogo: themeConfig.app.logo,
      appLogoTitle: themeConfig.app.logoTitle,
      t,

      icons: {
        mdiTrashCanOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
<style lang="scss" scoped>
// .v-text-field--outlined > .v-input__control > .v-input__slot {
.v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
  height: 65px;
  max-height: 65px;
  min-width: 65px;
  text-align: center;
  align-items: center;

  input {
    font-size: 1.25rem;
  }
}
</style>
